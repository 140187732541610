import { makeStyles } from "@material-ui/core";
import { colors } from "@utils/constant";

const TimeReportStyle = makeStyles({
  TimeReportWrapper: {
    "& .map-wrapper": {
      height: "0",
      paddingBottom: "30%",
      maxWidth: "100%",
      overflow: "hidden",
      "& .map-container ": {
        position: "relative",
        paddingBottom: "26.25%",
        paddingTop: "30px",
        height: 0,
        overflow: "hidden",
      },
      "@media(max-width:767px)": {
        paddingBottom: "40%",
      },
      "@media(max-width:574px)": {
        paddingBottom: "50%",
      },
    },
  },
  tabHeadingRow: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    backgroundColor: colors.lightBlue2,
    padding: "10px 10px"
  },
  titleWrapper: {
    "& h2": {
      fontWeight: "600",
      fontSize: "24px",
      textTransform: "capitalize",
      "@media (max-width: 767px)": {
        marginBottom: "10px",
      },
    },
  },
});

export { TimeReportStyle };
