import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { TimeReportStyle } from "./style";
import LiveTempTracking from "./live-temp";
import HistoricalTempTracking from "./historical-temp";
import Loader from "@components/loader";

function TempTracking() {
  const classes = TimeReportStyle();
  const TabHeading = ({ title }) => (
    <div className={classes.tabHeadingRow}>
      <div className={classes.titleWrapper}>
        <Typography variant="h2">{title}</Typography>
      </div>
    </div>
  );

  const [loadingState, setLoadingState] = useState({
    isLoadingLiveTemp: false,
    isLoadingHistoryTemp: false,
  });
  const setLoading = (type, loading) => {
    setLoadingState((prevState) => ({
      ...prevState,
      [type]: loading,
    }));
  };
  return (
    <div className={classes.TimeReportWrapper}>
      <Loader
        loading={
          loadingState.isLoadingHistoryTemp && loadingState.isLoadingLiveTemp
        }
      />
      <TabHeading title={"Live temp tracking"} />
      <LiveTempTracking
        onLoading={(loading) => setLoading("isLoadingLiveTemp", loading)}
      />
      <TabHeading title={"Historical temp tracking"} />
      <HistoricalTempTracking
        onLoading={(loading) => setLoading("isLoadingHistoryTemp", loading)}
      />
    </div>
  );
}
export default TempTracking;
