import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { TableStyle } from "./style";
import {
  jobStatusThemoKing,
  temperatureTrackingHeading,
} from "../../../../../utils/constant";
import { useParams } from "react-router-dom";
import { utcToTimezone } from "@utils/commonFunctions";
import useFetchTempTracking from "../../../../../hooks/fetchTempTracking";
import Pagination from "@components/pagination";
import { rowsPerPageVal } from "@utils/constant";
import { toast } from "react-toastify";

function HistoricalTempTracking({ onLoading }) {
  const classes = TableStyle();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageVal);
  const [param, setParam] = useState({
    page: page + 1,
    size: rowsPerPage,
  });

  const { data, isLoading, status, warning } = useFetchTempTracking(
    `jobs/${id}/thermoking/historicalTempTracking`, param
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  useEffect(() => {
    onLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setParam({
      page: page + 1,
      size: rowsPerPage,
    });
  }, [rowsPerPage, page]);

  useEffect(() => {
    warning && toast.warning(warning);
  }, [data]);

  return (
    <>
      {![jobStatusThemoKing.jobNotStarted].includes(status) && data ? (
        <>
          <div className={classes.TableWrapper}>
            <TableContainer component={Paper} className={classes.customTable}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {temperatureTrackingHeading.map((item, index) => {
                      return (
                        <TableCell key={index} className={item.className}>
                          {item.title}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.rows?.length > 0 ? (
                    data?.data?.rows.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {item.status ? (
                            <div>
                              <div>
                                <span>Type :</span> {item.status.type}
                              </div>
                              <div>
                                <span>Status:</span> {item.status.status}
                              </div>
                            </div>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.vehicle ? item.vehicle : "-"}
                        </TableCell>
                        <TableCell>
                          {item.date
                            ? utcToTimezone(
                                item.date,
                                item.timeZone,
                                "DD/MM/YY hh:mm A"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {item.setPoint1 ? item.setPoint1 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.returnAir1 ? item.returnAir1 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.dischargeAir1 ? item.dischargeAir1 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.setPoint2 ? item.setPoint2 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.returnAir2 ? item.returnAir2 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.dischargeAir2 ? item.dischargeAir2 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.setPoint3 ? item.setPoint3 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.returnAir3 ? item.returnAir3 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.dischargeAir3 ? item.dischargeAir3 : "-"}
                        </TableCell>
                        <TableCell>{item.power ? item.power : "-"}</TableCell>
                        <TableCell>
                          {item.sensor1 ? item.sensor1 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.sensor2 ? item.sensor2 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.sensor3 ? item.sensor3 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.sensor4 ? item.sensor4 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.sensor5 ? item.sensor5 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.sensor6 ? item.sensor6 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.touchLogSensor1 ? item.touchLogSensor1 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.touchLogSensor2 ? item.touchLogSensor2 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.touchLogSensor3 ? item.touchLogSensor3 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.touchLogSensor4 ? item.touchLogSensor4 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.touchLogSensor5 ? item.touchLogSensor5 : "-"}
                        </TableCell>
                        <TableCell>
                          {item.touchLogSensor6 ? item.touchLogSensor6 : "-"}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10}>
                        No temperature tracking data available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {data?.data?.count !== 0 && (
              <div className="pagination-wrapper">
                <Pagination
                  count={data?.data?.count}
                  page={page}
                  handleChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            )}
          </div>
        </>
      ) : isLoading ? (
        <div className={classes.loadingWrapper}>Loading...</div>
      ) : (
        <div className={classes.loadingWrapper}>
          No temperature tracking data available.
        </div>
      )}
    </>
  );
}
export default HistoricalTempTracking;
