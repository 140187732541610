import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Header from "@components/header";
import { routes } from "@utils/constant";
// import {
//   GET_ONLINE_REQUEST_BY_ID,
//   GET_ONLINE_REQUEST_BY_ID_SUCCESS,
//   GET_ONLINE_REQUEST_BY_ID_FAILURE,
// } from "@utils/actionTypes";
import { getApi } from "@services/axios";
import EditBlock from "./edit-block";
import Tabbing from "./tabbing";
import { JobDetailStyle } from "./style";

export default function DetailPage() {
  const classes = JobDetailStyle();
  const [onlineData, setOnlineData] = useState({});
  const { id } = useParams();
  const history = useHistory();

  let getOnlineRequestById = () => {
    // dispatch({ type: GET_ONLINE_REQUEST_BY_ID });
    getApi(`onlineRequests/${id}`)
      .then((response) => {
        // dispatch({
        //   type: GET_ONLINE_REQUEST_BY_ID_SUCCESS,
        //   payload: response.data.data,
        // });
        setOnlineData(response?.data?.data);
      })
      .catch((error) => {
        // dispatch({ type: GET_ONLINE_REQUEST_BY_ID_FAILURE, payload: error });
        if (error?.response?.status === 404) {
          history.push(routes.pageNotFound);
        }
      });
  };

  useEffect(() => {
    getOnlineRequestById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className={classes.JobDetailWrapper}>
        <div className="wrapper job-detail-page">
          <div className="inner-page">
            <div className={classes.backLinkWrapper}>
              <Link to={routes.onlineRequest} className={classes.backToPage}>
                <ArrowBackIosIcon /> Back to online request
              </Link>
            </div>
            <div className="detail-col-layout">
              <div className="left-block">
                <EditBlock
                  getOnlineRequestById={getOnlineRequestById}
                  onlineData={onlineData}
                />
              </div>
              <div className="right-block">
                <Tabbing
                    onlineData={onlineData}
                    getOnlineRequestById={getOnlineRequestById}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
