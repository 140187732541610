import { useEffect, useRef, useState, useMemo } from "react";
import { getApi } from "@services/axios";
import { jobStatusThemoKing } from "../../utils/constant";
import { toast } from "react-toastify";
import moment from "moment-timezone";

export const useFetchTempTracking = (url, paramQuery = {}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setsIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [warning, setWarning] = useState("");
  const intervalRef = useRef(null);

  const fetchData = async () => {
    setsIsLoading(true);
    try {
      const params = paramQuery;
      params.fromDate = moment().format("YYYY-MM-DD HH:mm:ss");
      const response = await getApi(url, { params });
      const responseData = response.data?.data;
      handleResponse(responseData);
    } catch (err) {
      setError(err);
      toast.error(err);
    } finally {
      setsIsLoading(false);
    }
  };

  const handleResponse = (responseData) => {
    setStatus(responseData?.statusJob);
    const isJobInProgress = responseData?.statusJob === jobStatusThemoKing.jobInProgress;
    if (isJobInProgress) {
      setWarning(responseData?.warning);
    }
    setData(responseData);
    if (responseData?.statusJob === jobStatusThemoKing.jobFinished) {
      clearInterval(intervalRef.current);
    }
  };
  const memoizedParamQuery = useMemo(() => JSON.stringify(paramQuery), [paramQuery]);
  useEffect(() => {
    fetchData();
    intervalRef.current = setInterval(fetchData, 60 * 1000); //fetch data every 1 minutes
    return () => clearInterval(intervalRef.current);
  }, [url, memoizedParamQuery]);

  return { data, isLoading, status, error, warning };
};
export default useFetchTempTracking;
