import React, { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";

import uncheckedIcon from "@assets/images/uncheck-icon.svg";
import checkedIcon from "@assets/images/checked-icon.svg";
import closeIcon from "@assets/images/close.svg";

import { PopupStyle } from "./style";

function Popup(props) {
	const classes = PopupStyle();
	const [scroll] = useState("body");

	return (
		<Dialog open={props.open} className={classes.customModal} scroll={scroll}>
			<div className="close-modal">
				<img src={closeIcon} alt="Close" onClick={props.handleClose} />
			</div>
			<form noValidate autoComplete="off" className={classes.customForm}>
				<DialogTitle>View ULD</DialogTitle>
				<DialogContent>
					<div className="form-row">
						<div className="form-group">
							<FormControl variant="outlined" className={classes.formControl}>
								<label className="label-text" htmlFor="additional-info">
									Volume (M²)
								</label>
								<TextField
									id="volume"
									placeholder="Volume"
									variant="outlined"
									type="text"
									value={props.formik.values.volume}
									disabled
								/>
							</FormControl>
						</div>
						<div className="form-group">
							<FormControl variant="outlined" className={classes.formControl}>
								<label className="label-text" htmlFor="quantity">
									Quantity
								</label>
								<TextField
									id="quantity"
									placeholder="0"
									variant="outlined"
									type="text"
									name="quantity"
									value={props.formik.values.quantity}
									disabled
								/>
							</FormControl>
						</div>
					</div>
					<div className="form-row">
						<div className="form-group">
							<FormControl variant="outlined" className={classes.formControl}>
								<label className="label-text" htmlFor="additional-info">
									ULD Number
								</label>
								<TextField
									id="uldNumber"
									name="uldNumber"
									placeholder="AKE12345DG4"
									variant="outlined"
									type="text"
									disabled
									onChange={props.formik.handleChange}
									value={props.formik.values.uldNumber.toUpperCase()}
									error={
										props.formik.touched.uldNumber &&
										Boolean(props.formik.errors.uldNumber)
									}
									helperText={
										props.formik.touched.uldNumber &&
										props.formik.errors.uldNumber
									}
								/>
							</FormControl>
						</div>
					</div>
					<div className="form-row">
						<div className="form-group full-width checkbox-wrapper">
							<FormControl component="fieldset">
								<FormGroup aria-label="position" className="custom-checkbox">
									<FormControlLabel
										value="end"
										control={
											<Checkbox
												name="overhang"
												onChange={props.formik.handleChange}
												value={props.formik.values.overhang}
												checked={props.formik.values.overhang}
												icon={<img src={uncheckedIcon} alt="CheckBox" />}
												checkedIcon={<img src={checkedIcon} alt="CheckBox" />}
												disabled
											/>
										}
										label="Is it an overhang?"
										labelPlacement="end"
									/>
								</FormGroup>
							</FormControl>
						</div>
					</div>
				</DialogContent>
			</form>
		</Dialog>
	);
}
export default Popup;
