import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { TableStyle } from "./style";
import {
  jobStatusThemoKing,
  temperatureTrackingHeading,
} from "../../../../../utils/constant";
import { useParams } from "react-router-dom";
import { utcToTimezone } from "@utils/commonFunctions";
import useFetchTempTracking from "../../../../../hooks/fetchTempTracking";
import { convertTempUnitToText } from "../../../../../utils/commonFunctions";
import trackingLogo from "@assets/images/tracking-logo-ws.png";

function LiveTempTracking({ onLoading }) {
  const classes = TableStyle();
  const { id } = useParams();
  
  const { data, status, isLoading } = useFetchTempTracking(
    `jobs/${id}/thermoking/liveTempTracking`
  );

  useEffect(() => {
    onLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      {[jobStatusThemoKing.jobInProgress].includes(status) && data ? (
        <>
          <div className={classes.tracking}>
            <img src={trackingLogo} alt="Wymap" />
            <div className="uld-list-wrapper">
              <div className="uld-list-item">
                Time Zone: {data.data?.timeZoneFullDisplay ?? "-"}
              </div>
              <div className="uld-list-item">
                Temperature Type:{" "}
                {data.data?.temperatureType
                  ? convertTempUnitToText(data.data?.temperatureType)
                  : "-"}
              </div>
              <div className="uld-list-item">
                Speed Type:
                {data.data?.speedType
                  ? data.data?.speedType.toUpperCase()
                  : "-"}
              </div>
              <div className="uld-list-item">
                Fuel Unit: {data.data?.fuelUnit ?? "-"}
              </div>
            </div>
          </div>
          <div className={classes.TableWrapper}>
            <TableContainer component={Paper} className={classes.customTable}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {temperatureTrackingHeading.map((item, index) => {
                      return (
                        <TableCell key={index} className={item.className}>
                          {item.title}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {data.data?.status ? (
                        <div>
                          <div>
                            <span>Type :</span> {data.data?.status.type}
                          </div>
                          <div>
                            <span>Status:</span> {data.data?.status.status}
                          </div>
                        </div>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {data.data?.vehicle ? data.data?.vehicle : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.date
                        ? utcToTimezone(
                            data.data?.date,
                            data.data?.timeZone,
                            "DD/MM/YY hh:mm A"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.setPoint1 ? data.data?.setPoint1 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.returnAir1 ? data.data?.returnAir1 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.dischargeAir1
                        ? data.data?.dischargeAir1
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.setPoint2 ? data.data?.setPoint2 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.returnAir2 ? data.data?.returnAir2 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.dischargeAir2
                        ? data.data?.dischargeAir2
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.setPoint3 ? data.data?.setPoint3 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.returnAir3 ? data.data?.returnAir3 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.dischargeAir3
                        ? data.data?.dischargeAir3
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.power ? data.data?.power : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.sensor1 ? data.data?.sensor1 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.sensor2 ? data.data?.sensor2 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.sensor3 ? data.data?.sensor3 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.sensor4 ? data.data?.sensor4 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.sensor5 ? data.data?.sensor5 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.sensor6 ? data.data?.sensor6 : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.touchLogSensor1
                        ? data.data?.touchLogSensor1
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.touchLogSensor2
                        ? data.data?.touchLogSensor2
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.touchLogSensor3
                        ? data.data?.touchLogSensor3
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.touchLogSensor4
                        ? data.data?.touchLogSensor4
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.touchLogSensor5
                        ? data.data?.touchLogSensor5
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data.data?.touchLogSensor6
                        ? data.data?.touchLogSensor6
                        : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : isLoading ? (
        <div className={classes.loadingWrapper}>Loading...</div>
      ) : (
        <div className={classes.loadingWrapper}>
          No temperature tracking data available.
        </div>
      )}
    </>
  );
}
export default LiveTempTracking;
